import React, { useEffect, useState } from 'react'
import Header from '../Layout/Header'
import Footer from '../Layout/Footer'
import InsidePageBanner from '../Component/InsidePageBanner'
import heroline from "../assets/images/hero-line.png"
import faqimg from "../assets/images/faqside.jpg"

import { getapi } from '../Api/Api'
import { BaseURL } from '../Api/BaseUrl'
import { useParams } from 'react-router-dom'

function OurVenture() {

    const [data, setdata] = useState([])
    const [team, setteam] = useState([])
    const { id } = useParams()
    const [faq, setfaq] = useState([])

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const handleget = async () => {
        let res = await getapi(`venturecontent/url/${id}`)
        setdata(res.data.data)
    }

    const handlefaq = async () => {
        let res = await getapi('faq')
        setfaq(res.data.data)
    }


    const handlegetteam = async () => {

        let res = await getapi('team')
        setteam(res.data.data)
    }

    useEffect(() => {
        handleget()
        handlegetteam()
        handlefaq()
    }, [id])
    return (
        <>


            <Header />
            <InsidePageBanner title="Venture" para="“A team of innovators and creatives who believe in doing things differently." />
            <section className='my-4'>
                <div className="container">

                    {data.map((item, index) => {
                        return (
                            <div className="row align-items-center mb-4" key={item._id}>
                                {index % 2 !== 0 ? (
                                    <>
                                        <div className="col-md-6">
                                            <img loading="lazy" src={`${BaseURL}${item.image}`} className='img-fluid rounded-3' alt="" />
                                        </div>
                                        <div className="col-md-6">
                                            <img loading="lazy" src={heroline} className='img-fluid' alt="" />
                                            <h3>{item.title}</h3>
                                            <p>{item.detail}</p>

                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-md-6">
                                            <img loading="lazy" src={heroline} className='img-fluid' alt="" />
                                            <h3>{item.title}</h3>
                                            <p>{item.detail}</p>

                                        </div>
                                        <div className="col-md-6">
                                            <img loading="lazy" src={`${BaseURL}${item.image}`} className='img-fluid rounded-3' alt="" />
                                        </div>
                                    </>
                                )}
                            </div>
                        );
                    })}
                </div>
            </section >

            {team.length > 0 && <section>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h3>Meet Our Team Member</h3>
                            </div>
                        </div>
                        {team.map((item, index) => {
                            return (
                                <>
                                    <div className="col-md-3">
                                        <div class="single-team-member">
                                            <div class="image">
                                                <img loading="lazy" src={`${BaseURL}${item.image}`} class="img-fluid rounded-circle" alt="" />
                                            </div>
                                            <div class="team-content mt-3">
                                                <h3>{item.title}</h3>
                                                <h6 class="t-orange fw-bold">
                                                    {item.position}</h6>
                                            </div>
                                            {/* <ul class="social-links d-flex justify-content-between px-4 py-1">
                                                <li ><a href="#" target="_blank"><i class="fab fa-facebook-f text-white"></i></a></li>
                                                <li><a href="#" target="_blank"><i class="fab fa-linkedin-in  text-white"></i></a></li>
                                                <li><a href="#" target="_blank"><i class="fab fa-twitter  text-white"></i></a></li>
                                                <li><a href="#" target="_blank"><i class="fab fa-instagram  text-white"></i></a></li>
                                            </ul> */}
                                        </div>
                                    </div>
                                </>
                            )
                        })}
                    </div>
                </div>
            </section>}



            <Footer />
        </>
    )
}

export default OurVenture
