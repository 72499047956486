import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import hero from "../assets/images/hero-bg.jpg"
import { getapi } from '../Api/Api';
import { BaseURL } from '../Api/BaseUrl';

const InsidePageBanner = (props) => {

    const location = useLocation()

    const { unique, detail, subdetail, url } = useParams()


    const about = location.pathname == '/about'


    const servicedetail = location.pathname == `/service-detail/${unique}`
    const servicesubdetail = location.pathname == `/service-detail/${detail}/${subdetail}`
    const career = location.pathname == `/career`
    const portfoliodetail = location.pathname == `/portfolio-detail/${unique}`
    const contact = location.pathname == `/contact`



    const allarticle = location.pathname == `/allarticles`
    const article = location.pathname == `/article-detail/${url}`



    const jobdetail = location.pathname == `/job-detail/${unique}`










    const [data, setdata] = useState([])

    const handleget = async () => {
        let url = ""
        if (about) {
            url = "About"
        } else if (servicedetail) {
            url = unique
        } else if (jobdetail) {
            url = "Job"
        } else if (allarticle || article) {
            url = "blog"
        } else if (servicesubdetail) {

            url = subdetail
        } else if (career) {
            url = "career"
        } else if (contact) {
            url = "contact"
        } else if (portfoliodetail) {
            url = unique
        }

        let res = await getapi(`bannertitle/${url}`)
        setdata(res?.data?.data)
        console.log(res?.data?.data)

    }

    useEffect(() => {
        handleget()
    }, [])
    return (
        <div className="page-title-area">
            <div className="section__bg" style={{ height: "380px" }}>
                <img loading="lazy" src={`${BaseURL}${data?.image}`} alt="" onError={(e) => { e.target.onerror = null; e.target.src = hero }} className='w-100 ' style={{ height: "380px", objectFit: "cover" }} />
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-title-content text-center">
                            <div className="" style={{ opacity: "0" }}>
                                <h3 className="title mb-0">{props.title}</h3>
                                {props.para && <p className='text-white'>{props.para}</p>}
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to={'/'}>Home</Link></li>
                                        <li className="breadcrumb-item active" aria-current="page">{props.title}</li>
                                    </ol>
                                </nav>
                            </div>
                            {/* <div className="scroll-to-down">
                                <a href="#portfolio-scroll"><i className="fal fa-arrow-down"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InsidePageBanner;
