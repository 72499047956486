// export const BaseURL = 'http://localhost:9011/'

export const BaseURL = "https://iamparevartan.org:9011/"

// export const BaseURL = 'https://62.72.30.137:9011/'





